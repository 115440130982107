import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './App.css'
import Dashboard from './components/Dashboard/Dashboard'
import Terms from './views/Terms'
import RefundPolicy from './views/RefundPolicy'
import { AuthProvider } from './auth/AuthProvider'

function App() {
  return (
    <AuthProvider>
      <div className="App">
        <Router>
          <div className="container">
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/return-policy" element={<RefundPolicy />} />
              <Route path="/terms-of-service" element={<Terms />} />
            </Routes>
          </div>
        </Router>
      </div>
    </AuthProvider>
  )
}

export default App
