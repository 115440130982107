import { createContext, useContext } from 'react'
import { useAuth } from './useAuth'
import { Spinner } from '../components/Spinner/Spinner'

/**
 * @type {React.Context<null | { token: string }>}
 */
const AuthContext = createContext(null)

/**
 * Prevents children from rendering unless user's token is valid
 */
export const AuthProvider = ({ children }) => {
  const { token } = useAuth()

  return (
    <AuthContext.Provider value={{ token }}>
      {token ? (
        children
      ) : (
        <div style={{ display: 'flex', placeItems: 'center', height: '100vh' }}>
          <Spinner />
        </div>
      )}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => {
  const context = useContext(AuthContext)

  if (context === null)
    throw new Error('Please, wrap your component with AuthProvider')

  return context
}
