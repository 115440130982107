import { useCallback, useEffect, useRef, useState } from 'react'

/**
 * Validates users token
 * @returns {{ token: string | null }}
 */
export const useAuth = () => {
  const [token, setToken] = useState(null)
  const isVerifyingRef = useRef(false)

  const retrieveTokenFromUrl = useCallback(() => {
    const query = new URLSearchParams(window.location.search)
    const token = query.get('token')

    if (!token) return null

    const url = new URL(window.location.href)
    url.searchParams.delete('token')
    window.history.replaceState(null, null, url)

    return token
  }, [])

  const retrieveTokenFromCache = useCallback(() => {
    const token = localStorage.getItem('token')

    if (token) localStorage.removeItem('token')

    return token
  }, [])

  const saveTokenToCache = useCallback(token => {
    localStorage.setItem('token', token)
  }, [])

  const verifyToken = useCallback(
    async token => {
      const res = await fetch(
        'https://zx8hsmle2j.execute-api.eu-central-1.amazonaws.com/production/api/v1/auth/verify?token=' +
          token,
        {
          method: 'GET',
        }
      )

      const data = await res.json()

      data?.success && saveTokenToCache(token)

      return Boolean(data?.success)
    },
    [saveTokenToCache]
  )

  const signIn = useCallback(() => {
    window.location.href = 'https://auth.welcomenocode.com?app=databasemigration'
  }, [])

  const verifyAndSaveToken = useCallback(async () => {
    isVerifyingRef.current = true

    const tokenFromUrl = retrieveTokenFromUrl()

    if (tokenFromUrl && (await verifyToken(tokenFromUrl)))
      return setToken(tokenFromUrl)

    const tokenFromCache = retrieveTokenFromCache()

    if (tokenFromCache && (await verifyToken(tokenFromCache)))
      return setToken(tokenFromCache)

    isVerifyingRef.current = false
    signIn()
  }, [retrieveTokenFromUrl, verifyToken, retrieveTokenFromCache, signIn])

  useEffect(() => {
    if (isVerifyingRef.current) return

    verifyAndSaveToken().catch(console.error)
  }, [verifyAndSaveToken])

  return { token }
}
