import React, { useState } from 'react'
import './Dashboard.css'
import LogoIcon from '../../icons/logo.png'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios'

function Dashboard() {
  const [isDark, setDark] = useState(false)
  const [databaseFrom, setDatabaseFrom] = useState('firestore')
  const [databaseTo, setDatabaseTo] = useState(null)
  const [URI_from, setURI_from] = useState(null)
  const [URI_to, setURI_to] = useState(null)
  const [database, setDatabase] = useState(null)
  const [firestore_key, setFirestoreKey] = useState(null)
  const [firestore_project_id, setFirestoreProjectId] = useState(null)

  const uploadKey = (event) => {
    var uploadedFile = event.target.files[0]; 
    
    if(uploadedFile.type !== "application/json") { 
        alert("Wrong file type"); 
        return false;
    }
    
    if (uploadedFile) {
        var readFile = new FileReader();
        readFile.onload = function(e) { 
            var contents = e.target.result;
            var json = JSON.parse(contents);
            setFirestoreKey(json)
        };
        readFile.readAsText(uploadedFile);
    } else { 
        console.log("Failed to load file");
    }
  }

  const migrate = () => {
    if (databaseFrom === 'mongodb' && !URI_from) return alert('"URI from where the database will be copied" is not provided');
    if (databaseTo === 'mongodb' && !URI_to) return alert('"URI where the database will be migrated" is not provided');
    if (databaseTo === 'postgresql' && !URI_to) return alert('"URI where the database will be migrated" is not provided');
    if (databaseFrom === 'postgresql' && !URI_from) return alert('"URI where the database will be migrated" is not provided');

    if (databaseFrom === 'mongodb' || databaseTo === 'mongodb') {
      if (!database) return alert('"Database name" is not provided')
    } 

    if (databaseFrom === 'firestore' || databaseTo === 'firestore') {
      if (!firestore_key) return alert('"Firestore key" is not provided')
      if (!firestore_project_id) return alert('"Firestore project Id" is not provided')
    }

    const token = localStorage.getItem('token')

    // mongo to postgresql
    if (databaseFrom === 'mongodb' && databaseTo === 'postgresql') {
      axios.post('https://lji8vf2vvi.execute-api.eu-central-1.amazonaws.com/production/api/v1/migrations/mongodb-postgresql?token=' + token, {
        mongoURI: URI_from,
        pgURI: URI_to,
        mongoDbName: database
      }).catch(() => {
        toast('Migration failed! Double check the URI', { type: 'error' });
      })
    }

    // postgresql to mongo
    if (databaseFrom === 'postgresql' && databaseTo === 'mongodb') {
      axios.post('https://lji8vf2vvi.execute-api.eu-central-1.amazonaws.com/production/api/v1/migrations/postgresql-mongodb?token=' + token, {
        mongoURI: databaseTo,
        pgURI: databaseFrom,
        mongoDbName: database
      }).catch(() => {
        toast('Migration failed! Double check the URI', { type: 'error' });
      })
    }

    // firestore to mongo
    if (databaseFrom === 'firestore' && databaseTo === 'mongodb') {
      axios.post('https://lji8vf2vvi.execute-api.eu-central-1.amazonaws.com/production/api/v1/migrations/firestore-mongodb?token=' + token, {
        mongoURI: databaseTo,
        fireStore_key: firestore_key,
        fireStore_projectId: firestore_project_id,
        mongoDbName: database
      }).catch(() => {
        toast('Migration failed! Double check the URI', { type: 'error' });
      })
    }

    // mongo to firestore
    if (databaseFrom === 'mongo' && databaseTo === 'firestore') {
      axios.post('https://lji8vf2vvi.execute-api.eu-central-1.amazonaws.com/production/api/v1/migrations/mongodb-firestore?token=' + token, {
        mongoURI: databaseTo,
        fireStore_key: firestore_key,
        fireStore_projectId: firestore_project_id,
        mongoDbName: database
      }).catch(() => {
        toast('Migration failed! Double check the URI', { type: 'error' });
      })
    }

    toast('Migration started! You will receive a notification when the migration is done.', { type: 'success' });
  }

  return (
    <div className="Dashboard">
      <div className={isDark ? 'Constructor dark' : 'Constructor white'}>
        <div className="Header">
          <div className="SubHeader">
            <img className="logo" alt="Logo" src={LogoIcon} />
            <span>
              <span className="blue">DatabaseMigration</span>.Cheap
            </span>
            <p
              className="blue"
              onClick={() =>
                window.open('https://www.welcomenocode.com/', '_blank')
              }
            >
              Provided by WNC
            </p>
          </div>
        </div>
        <div className="Body">
          <div className='From'>
            <select className='From_Select' onChange={(e) => {
                if (e.target.value === 'mongodb') setDatabaseTo('firestore'); 
                if (e.target.value === 'firestore') setDatabaseTo('mongodb'); 
                setDatabaseFrom(e.target.value)
              }}>
              <option value='firestore'>Firestore</option>
              <option value='mongodb'>MongoDB</option>
              <option value='postgresql'>PostgreSQL</option>
            </select>
            { (databaseFrom === 'firestore') ? <input onChange={(e) => setFirestoreProjectId(e.target.value)} className='ProjectId' placeholder='Firestore project Id'/> : <></> }
            { (databaseFrom !== 'firestore') ? <input className='URI_Input' placeholder='URI from where the database will be copied' onChange={(e) => setURI_from(e.target.value)}/> : <></> }
          </div>
          <div className='To'>
            { (databaseTo === 'firestore') ? <input onChange={(e) => setFirestoreProjectId(e.target.value)} className='ProjectId' placeholder='Firestore project Id'/> : <></> }
            { (databaseTo !== 'firestore') ? <input className='URI_Input' placeholder='URI where the database will be migrated' onChange={(e) => setURI_to(e.target.value)}/> : <></> }
            <select className='To_Select' onChange={(e) => {
              setDatabaseTo(e.target.value)
            }}>
              { databaseFrom === 'mongodb' ? <option value='firestore'>Firestore</option> : <></> }
              { (databaseFrom === 'firestore' || databaseFrom === 'postgresql') ? <option value='mongodb'>MongoDB</option> : <></> }
              { databaseFrom === 'mongodb' ? <option value='postgresql'>PostgreSQL</option> : <></> }
            </select>
          </div>
          <div>
            { (databaseFrom === 'mongodb' || databaseTo == 'mongodb') ? <input onChange={(e) => setDatabase(e.target.value)} className='Database_Name' placeholder='MongoDB database name which should be copied'/> : <></> }
            { databaseFrom === 'postgresql' ? <input onChange={(e) => setDatabase(e.target.value)} className='Database_Name' placeholder='Give name for new database (optional)'/> : <></> }
            { (databaseFrom === 'firestore' || databaseTo === 'firestore') ? 
                <div className='File_Upload_Container'>
                  <div className={isDark ? 'File_Upload_dark' : 'File_Upload_white'}>
                    Select serviceAccountKey.json
                    <input onChange={(e) => uploadKey(e)} className='serviceAccountKey' type='file' title='serviceAccountKey.json'/>
                  </div>
                </div>
              : <></> }
          </div>
          <div>
            <button className='Migarate_Button' onClick={() => migrate()}>
              Migarate
            </button>
          </div>
        </div>
      </div>
      <div className={isDark ? 'RightMenu dark' : 'RightMenu white'}>
        <div className={isDark ? 'Theme white' : 'Theme dark'}>
          <div
            className={isDark ? 'Sun white' : 'Sun dark'}
            onClick={() => setDark(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill={isDark ? '#1b1f23' : '#fff'}
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M20 8.69V4h-4.69L12 .69 8.69 4H4v4.69L.69 12 4 15.31V20h4.69L12 23.31 15.31 20H20v-4.69L23.31 12 20 8.69zM12 18c-3.31 0-6-2.69-6-6s2.69-6 6-6 6 2.69 6 6-2.69 6-6 6zm0-10c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4z" />
            </svg>
          </div>
          <div
            className={isDark ? 'Moon dark' : 'Moon white'}
            onClick={() => setDark(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              enable-background="new 0 0 24 24"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill={isDark ? '#fff' : '#1b1f23'}
            >
              <g>
                <path d="M0,0h24v24H0V0z" fill="none" />
              </g>
              <g>
                <g>
                  <g>
                    <path d="M14,2c1.82,0,3.53,0.5,5,1.35C16.01,5.08,14,8.3,14,12s2.01,6.92,5,8.65C17.53,21.5,15.82,22,14,22C8.48,22,4,17.52,4,12 S8.48,2,14,2z" />
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </div>
      </div>
      <ToastContainer theme={isDark ? 'dark' : 'light'} position="top-right" />
    </div>
  )
}

export default Dashboard
